import React from 'react'
import Form from '../components/Form'
/* import Video from '../components/Video' */
import client_1 from '../assets/images/client_1.jpg'
import client_2 from '../assets/images/client_2.jpg'
import client_3 from '../assets/images/client_3.jpg'
import client_4 from '../assets/images/client_4.jpg'
import client_5 from '../assets/images/client_5.jpg'
import client_6 from '../assets/images/client_6.jpg'
import client_7 from '../assets/images/client_7.jpg'
import whatsapp from '../assets/images/whatsapp.png'
import instagram from '../assets/images/instagram.png'
import facebook from '../assets/images/facebook.png'
import bv from '../assets/images/bv.png'
import solfacil from '../assets/images/solfacil.png'
import sicredi from '../assets/images/sicredi.jpg'
import santander from '../assets/images/santander.png'
import sol_agora from '../assets/images/sol_agora.png'
import bradesco from '../assets/images/bradesco.png'

export default function Home() {
    return (
        <div>
            <Form />
            {/* <Video /> */}
            <hr className='hr'></hr>
            <h1 className='home-titles'>Benefícios da energia solar</h1>
            <hr className='hr'></hr>
            <div className='info-1'>
                <div className='info-icon'>📊</div>
                <p>Vida útil de mais de 25 anos, com retorno de investimento em até 3 anos</p>
                <hr className='hr-2'></hr>
                <div className='info-icon'>💵</div>
                <p>Economia de até 85% da conta de luz</p>
                <hr className='hr-2'></hr>
                <div className='info-icon'>🏡</div>
                <p>Valoriza seu imóvel</p>
                <hr className='hr-2'></hr>
                <div className='info-icon'>🛠️</div>
                <p>Necessidade mínima de manutenção</p>
            </div>
            <hr className='hr'></hr>
            <h1 className='home-titles'>Conheça alguns clientes:</h1>
            <hr className='hr'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Editael Nascimento</p>
                <p><strong>Média da geração:</strong> 1.900 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Cajazeiras X - Salvador</p>
                <p><strong>Tamanho do sistema:</strong> 14,4 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN 10000TL-X</p>
                <p><strong>Módulos:</strong> 32 unidades da marca JINKO</p>
                <img
                    src={client_1}
                    alt='Client 1'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Maria Filha</p>
                <p><strong>Média da geração:</strong> 1.800 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Abrantes - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 11,8 kWp</p>
                <p><strong>Microinversor:</strong> HOYMILES HMS-2000-4T</p>
                <p><strong>Módulos:</strong> 20 unidades da marca LEAPTON SOLAR</p>
                <img
                    src={client_2}
                    alt='Client 2'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Sindicelpa</p>
                <p><strong>Média da geração:</strong> 1.300 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Gleba A - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 10,8 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN8000TL-X(E)</p>
                <p><strong>Módulos:</strong> 24 unidades da marca JINKO</p>
                <img
                    src={client_3}
                    alt='Client 3'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Josemar</p>
                <p><strong>Média da geração:</strong> 400 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Vida Nova - Lauro de Freitas</p>
                <p><strong>Tamanho do sistema:</strong> 4,44 kWp</p>
                <p><strong>Microinversor:</strong> HOYMILES HMS-1800-4T</p>
                <p><strong>Módulos:</strong> 8 unidades da marca LEAPTON</p>
                <img
                    src={client_4}
                    alt='Client 4'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Cláudio Andrade</p>
                <p><strong>Média da geração:</strong> 200 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Morro do Chapéu</p>
                <p><strong>Tamanho do sistema:</strong> 1,84 kWp</p>
                <p><strong>Microinversor:</strong> DEYE SUN2000G3-US-220</p>
                <p><strong>Módulos:</strong> 4 unidades da marca JINKO</p>
                <img
                    src={client_5}
                    alt='Client 5'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Manoel Santos</p>
                <p><strong>Média da geração:</strong> 930 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Phoc II - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 8,25 kWp</p>
                <p><strong>Inversor:</strong> SUNGROW SG6.0RS</p>
                <p><strong>Módulos:</strong> 15 unidades da marca JA SOLAR</p>
                <img
                    src={client_6}
                    alt='Client 6'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Edgard Pattas</p>
                <p><strong>Média da geração:</strong> 833 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Castelo Branco - Salvador</p>
                <p><strong>Tamanho do sistema:</strong> 7,7 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN 6000TL-X</p>
                <p><strong>Módulos:</strong> 14 unidades da marca BELENERGY</p>
                <img
                    src={client_7}
                    alt='Client 7'
                />
            </div>
            <hr className='hr'></hr>
            <h1 className='home-titles'>Bancos parceiros:</h1>
            <hr className='hr'></hr>
            <div className='container-banks'>
                <img
                    className='grid-item'
                    src={bv}
                    alt='Logo da BV'
                />
                <img
                    className='grid-item'
                    src={solfacil}
                    alt='Logo da Solfácil'
                />
                <img
                    className='grid-item'
                    src={sol_agora}
                    alt='Logo da Sol Agora'
                />
                <img
                    className='grid-item'
                    src={sicredi}
                    alt='Logo da Sicredi'
                />
                <img
                    className='grid-item'
                    src={santander}
                    alt='Logo do santander'
                />
                <img
                    className='grid-item-bradesco'
                    src={bradesco}
                    alt='Logo do Bradesco'
                />
            </div>
            <hr className='hr'></hr>
            <h1 className='home-titles'>Quer saber mais?</h1>
            <hr className='hr'></hr>
            <div className='social-media'>
                <a href='#form'><button>FAÇA UM ORÇAMENTO GRATUITO AGORA</button></a><br></br>
                <p>Acompanhe a nossa empresa nas redes sociais e canais de relacionamento:</p>
                <div className='social-media-buttons'>
                    <a href='https://api.whatsapp.com/send/?phone=5571993328396&text&type=phone_number&app_absent=0'><img
                        src={whatsapp}
                        alt='WhatsApp logo'
                    /></a>
                    <a href='https://www.instagram.com/conduseletrica/'><img
                        src={instagram}
                        alt='Instagram logo'
                    /></a>
                    <a href='https://web.facebook.com/profile.php?id=100063819270106'><img
                        src={facebook}
                        alt='Facebook logo'
                    /></a>
                </div>
            </div>
            <div>
                <a
                    href="https://wa.me//5571993328396?text=Tenho%20interesse%20em%20fazer%20um%20orçamento%20de%20energia%20solar!"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={whatsapp}
                        alt='Logo do WhatsApp'
                        className="whatsapp-button"
                    />
                </a>
            </div>
        </div>
    )
}
